import { default as createkJtzwsFN6DMeta } from "/builds/leviia/console/pages/bucket/create.vue?macro=true";
import { default as _91id_93UBjHeFZj20Meta } from "/builds/leviia/console/pages/bucket/edit/[id].vue?macro=true";
import { default as bucketsy22TosoQK0Meta } from "/builds/leviia/console/pages/buckets.vue?macro=true";
import { default as indexFsvxDLM4izMeta } from "/builds/leviia/console/pages/debug/index.vue?macro=true";
import { default as drive_45perso1ozvTZglApMeta } from "/builds/leviia/console/pages/discover/drive-perso.vue?macro=true";
import { default as drive_45proLzqWedMLjCMeta } from "/builds/leviia/console/pages/discover/drive-pro.vue?macro=true";
import { default as nextcloud_45massif28WXofMQf9Meta } from "/builds/leviia/console/pages/discover/nextcloud-massif.vue?macro=true";
import { default as object_45storage4cqZku30frMeta } from "/builds/leviia/console/pages/discover/object-storage.vue?macro=true";
import { default as drive_45pros9HR3EgHsjdMeta } from "/builds/leviia/console/pages/drive-pros.vue?macro=true";
import { default as createCHSHC1C6yeMeta } from "/builds/leviia/console/pages/identifier/create.vue?macro=true";
import { default as _91id_93YFFCA0XsZ9Meta } from "/builds/leviia/console/pages/identifier/edit/[id].vue?macro=true";
import { default as identifiersxcKptsKf4IMeta } from "/builds/leviia/console/pages/identifiers.vue?macro=true";
import { default as indexsE15W18sBBMeta } from "/builds/leviia/console/pages/index.vue?macro=true";
import { default as logoutrA6arH2zjSMeta } from "/builds/leviia/console/pages/logout.vue?macro=true";
import { default as index31nUCc3tLkMeta } from "/builds/leviia/console/pages/partners/index.vue?macro=true";
import { default as silent_45check_45ssozs55rXZYH3Meta } from "/builds/leviia/console/pages/silent-check-sso.vue?macro=true";
import { default as bucketsJznDszsXkiMeta } from "/builds/leviia/console/pages/statistiques/details/buckets.vue?macro=true";
import { default as identifiersgmD7vlBCtqMeta } from "/builds/leviia/console/pages/statistiques/details/identifiers.vue?macro=true";
import { default as indexHusQwOI7y8Meta } from "/builds/leviia/console/pages/statistiques/index.vue?macro=true";
import { default as edithYTbMvpYaxMeta } from "/builds/leviia/console/pages/team/member/[id]/edit.vue?macro=true";
import { default as createxKvE2l2lFZMeta } from "/builds/leviia/console/pages/team/member/create.vue?macro=true";
import { default as testetXESqyTkfMeta } from "/builds/leviia/console/pages/test.vue?macro=true";
import { default as detailsx8zWDYbKA0Meta } from "/builds/leviia/console/pages/user/billing/details.vue?macro=true";
import { default as invoicesw2xdQEhVtxMeta } from "/builds/leviia/console/pages/user/billing/invoices.vue?macro=true";
import { default as payment_45methodsRYs1SRdGfKMeta } from "/builds/leviia/console/pages/user/billing/payment-methods.vue?macro=true";
import { default as infosxZr5kbItZIMeta } from "/builds/leviia/console/pages/user/infos.vue?macro=true";
import { default as security4NOa3VfHSBMeta } from "/builds/leviia/console/pages/user/security.vue?macro=true";
import { default as drive_45pro0hXYebJuFpMeta } from "/builds/leviia/console/pages/user/subscriptions/drive-pro.vue?macro=true";
import { default as driveqLLZcUcIb7Meta } from "/builds/leviia/console/pages/user/subscriptions/drive.vue?macro=true";
import { default as indexR9x1vosQvMMeta } from "/builds/leviia/console/pages/user/subscriptions/index.vue?macro=true";
import { default as no_45subxk19PBO5crMeta } from "/builds/leviia/console/pages/user/subscriptions/no-sub.vue?macro=true";
import { default as object_45storagebTSvacDlVXMeta } from "/builds/leviia/console/pages/user/subscriptions/object-storage.vue?macro=true";
import { default as partnerNdIRaDpwHWMeta } from "/builds/leviia/console/pages/user/subscriptions/partner.vue?macro=true";
import { default as teamXP0SNTzMkqMeta } from "/builds/leviia/console/pages/user/team.vue?macro=true";
export default [
  {
    name: createkJtzwsFN6DMeta?.name ?? "bucket-create",
    path: createkJtzwsFN6DMeta?.path ?? "/bucket/create",
    meta: createkJtzwsFN6DMeta || {},
    alias: createkJtzwsFN6DMeta?.alias || [],
    redirect: createkJtzwsFN6DMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/bucket/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UBjHeFZj20Meta?.name ?? "bucket-edit-id",
    path: _91id_93UBjHeFZj20Meta?.path ?? "/bucket/edit/:id()",
    meta: _91id_93UBjHeFZj20Meta || {},
    alias: _91id_93UBjHeFZj20Meta?.alias || [],
    redirect: _91id_93UBjHeFZj20Meta?.redirect,
    component: () => import("/builds/leviia/console/pages/bucket/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: bucketsy22TosoQK0Meta?.name ?? "buckets",
    path: bucketsy22TosoQK0Meta?.path ?? "/buckets",
    meta: bucketsy22TosoQK0Meta || {},
    alias: bucketsy22TosoQK0Meta?.alias || [],
    redirect: bucketsy22TosoQK0Meta?.redirect,
    component: () => import("/builds/leviia/console/pages/buckets.vue").then(m => m.default || m)
  },
  {
    name: indexFsvxDLM4izMeta?.name ?? "debug",
    path: indexFsvxDLM4izMeta?.path ?? "/debug",
    meta: indexFsvxDLM4izMeta || {},
    alias: indexFsvxDLM4izMeta?.alias || [],
    redirect: indexFsvxDLM4izMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/debug/index.vue").then(m => m.default || m)
  },
  {
    name: drive_45perso1ozvTZglApMeta?.name ?? "discover-drive-perso",
    path: drive_45perso1ozvTZglApMeta?.path ?? "/discover/drive-perso",
    meta: drive_45perso1ozvTZglApMeta || {},
    alias: drive_45perso1ozvTZglApMeta?.alias || [],
    redirect: drive_45perso1ozvTZglApMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/discover/drive-perso.vue").then(m => m.default || m)
  },
  {
    name: drive_45proLzqWedMLjCMeta?.name ?? "discover-drive-pro",
    path: drive_45proLzqWedMLjCMeta?.path ?? "/discover/drive-pro",
    meta: drive_45proLzqWedMLjCMeta || {},
    alias: drive_45proLzqWedMLjCMeta?.alias || [],
    redirect: drive_45proLzqWedMLjCMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/discover/drive-pro.vue").then(m => m.default || m)
  },
  {
    name: nextcloud_45massif28WXofMQf9Meta?.name ?? "discover-nextcloud-massif",
    path: nextcloud_45massif28WXofMQf9Meta?.path ?? "/discover/nextcloud-massif",
    meta: nextcloud_45massif28WXofMQf9Meta || {},
    alias: nextcloud_45massif28WXofMQf9Meta?.alias || [],
    redirect: nextcloud_45massif28WXofMQf9Meta?.redirect,
    component: () => import("/builds/leviia/console/pages/discover/nextcloud-massif.vue").then(m => m.default || m)
  },
  {
    name: object_45storage4cqZku30frMeta?.name ?? "discover-object-storage",
    path: object_45storage4cqZku30frMeta?.path ?? "/discover/object-storage",
    meta: object_45storage4cqZku30frMeta || {},
    alias: object_45storage4cqZku30frMeta?.alias || [],
    redirect: object_45storage4cqZku30frMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/discover/object-storage.vue").then(m => m.default || m)
  },
  {
    name: drive_45pros9HR3EgHsjdMeta?.name ?? "drive-pros",
    path: drive_45pros9HR3EgHsjdMeta?.path ?? "/drive-pros",
    meta: drive_45pros9HR3EgHsjdMeta || {},
    alias: drive_45pros9HR3EgHsjdMeta?.alias || [],
    redirect: drive_45pros9HR3EgHsjdMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/drive-pros.vue").then(m => m.default || m)
  },
  {
    name: createCHSHC1C6yeMeta?.name ?? "identifier-create",
    path: createCHSHC1C6yeMeta?.path ?? "/identifier/create",
    meta: createCHSHC1C6yeMeta || {},
    alias: createCHSHC1C6yeMeta?.alias || [],
    redirect: createCHSHC1C6yeMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/identifier/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YFFCA0XsZ9Meta?.name ?? "identifier-edit-id",
    path: _91id_93YFFCA0XsZ9Meta?.path ?? "/identifier/edit/:id()",
    meta: _91id_93YFFCA0XsZ9Meta || {},
    alias: _91id_93YFFCA0XsZ9Meta?.alias || [],
    redirect: _91id_93YFFCA0XsZ9Meta?.redirect,
    component: () => import("/builds/leviia/console/pages/identifier/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: identifiersxcKptsKf4IMeta?.name ?? "identifiers",
    path: identifiersxcKptsKf4IMeta?.path ?? "/identifiers",
    meta: identifiersxcKptsKf4IMeta || {},
    alias: identifiersxcKptsKf4IMeta?.alias || [],
    redirect: identifiersxcKptsKf4IMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/identifiers.vue").then(m => m.default || m)
  },
  {
    name: indexsE15W18sBBMeta?.name ?? "index",
    path: indexsE15W18sBBMeta?.path ?? "/",
    meta: indexsE15W18sBBMeta || {},
    alias: indexsE15W18sBBMeta?.alias || [],
    redirect: indexsE15W18sBBMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logoutrA6arH2zjSMeta?.name ?? "logout",
    path: logoutrA6arH2zjSMeta?.path ?? "/logout",
    meta: logoutrA6arH2zjSMeta || {},
    alias: logoutrA6arH2zjSMeta?.alias || [],
    redirect: logoutrA6arH2zjSMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: index31nUCc3tLkMeta?.name ?? "partners",
    path: index31nUCc3tLkMeta?.path ?? "/partners",
    meta: index31nUCc3tLkMeta || {},
    alias: index31nUCc3tLkMeta?.alias || [],
    redirect: index31nUCc3tLkMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: silent_45check_45ssozs55rXZYH3Meta?.name ?? "silent-check-sso",
    path: silent_45check_45ssozs55rXZYH3Meta?.path ?? "/silent-check-sso",
    meta: silent_45check_45ssozs55rXZYH3Meta || {},
    alias: silent_45check_45ssozs55rXZYH3Meta?.alias || [],
    redirect: silent_45check_45ssozs55rXZYH3Meta?.redirect,
    component: () => import("/builds/leviia/console/pages/silent-check-sso.vue").then(m => m.default || m)
  },
  {
    name: bucketsJznDszsXkiMeta?.name ?? "statistiques-details-buckets",
    path: bucketsJznDszsXkiMeta?.path ?? "/statistiques/details/buckets",
    meta: bucketsJznDszsXkiMeta || {},
    alias: bucketsJznDszsXkiMeta?.alias || [],
    redirect: bucketsJznDszsXkiMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/statistiques/details/buckets.vue").then(m => m.default || m)
  },
  {
    name: identifiersgmD7vlBCtqMeta?.name ?? "statistiques-details-identifiers",
    path: identifiersgmD7vlBCtqMeta?.path ?? "/statistiques/details/identifiers",
    meta: identifiersgmD7vlBCtqMeta || {},
    alias: identifiersgmD7vlBCtqMeta?.alias || [],
    redirect: identifiersgmD7vlBCtqMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/statistiques/details/identifiers.vue").then(m => m.default || m)
  },
  {
    name: indexHusQwOI7y8Meta?.name ?? "statistiques",
    path: indexHusQwOI7y8Meta?.path ?? "/statistiques",
    meta: indexHusQwOI7y8Meta || {},
    alias: indexHusQwOI7y8Meta?.alias || [],
    redirect: indexHusQwOI7y8Meta?.redirect,
    component: () => import("/builds/leviia/console/pages/statistiques/index.vue").then(m => m.default || m)
  },
  {
    name: edithYTbMvpYaxMeta?.name ?? "team-member-id-edit",
    path: edithYTbMvpYaxMeta?.path ?? "/team/member/:id()/edit",
    meta: edithYTbMvpYaxMeta || {},
    alias: edithYTbMvpYaxMeta?.alias || [],
    redirect: edithYTbMvpYaxMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/team/member/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createxKvE2l2lFZMeta?.name ?? "team-member-create",
    path: createxKvE2l2lFZMeta?.path ?? "/team/member/create",
    meta: createxKvE2l2lFZMeta || {},
    alias: createxKvE2l2lFZMeta?.alias || [],
    redirect: createxKvE2l2lFZMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/team/member/create.vue").then(m => m.default || m)
  },
  {
    name: testetXESqyTkfMeta?.name ?? "test",
    path: testetXESqyTkfMeta?.path ?? "/test",
    meta: testetXESqyTkfMeta || {},
    alias: testetXESqyTkfMeta?.alias || [],
    redirect: testetXESqyTkfMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/test.vue").then(m => m.default || m)
  },
  {
    name: detailsx8zWDYbKA0Meta?.name ?? "user-billing-details",
    path: detailsx8zWDYbKA0Meta?.path ?? "/user/billing/details",
    meta: detailsx8zWDYbKA0Meta || {},
    alias: detailsx8zWDYbKA0Meta?.alias || [],
    redirect: detailsx8zWDYbKA0Meta?.redirect,
    component: () => import("/builds/leviia/console/pages/user/billing/details.vue").then(m => m.default || m)
  },
  {
    name: invoicesw2xdQEhVtxMeta?.name ?? "user-billing-invoices",
    path: invoicesw2xdQEhVtxMeta?.path ?? "/user/billing/invoices",
    meta: invoicesw2xdQEhVtxMeta || {},
    alias: invoicesw2xdQEhVtxMeta?.alias || [],
    redirect: invoicesw2xdQEhVtxMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/user/billing/invoices.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodsRYs1SRdGfKMeta?.name ?? "user-billing-payment-methods",
    path: payment_45methodsRYs1SRdGfKMeta?.path ?? "/user/billing/payment-methods",
    meta: payment_45methodsRYs1SRdGfKMeta || {},
    alias: payment_45methodsRYs1SRdGfKMeta?.alias || [],
    redirect: payment_45methodsRYs1SRdGfKMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/user/billing/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: infosxZr5kbItZIMeta?.name ?? "user-infos",
    path: infosxZr5kbItZIMeta?.path ?? "/user/infos",
    meta: infosxZr5kbItZIMeta || {},
    alias: infosxZr5kbItZIMeta?.alias || [],
    redirect: infosxZr5kbItZIMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/user/infos.vue").then(m => m.default || m)
  },
  {
    name: security4NOa3VfHSBMeta?.name ?? "user-security",
    path: security4NOa3VfHSBMeta?.path ?? "/user/security",
    meta: security4NOa3VfHSBMeta || {},
    alias: security4NOa3VfHSBMeta?.alias || [],
    redirect: security4NOa3VfHSBMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/user/security.vue").then(m => m.default || m)
  },
  {
    name: drive_45pro0hXYebJuFpMeta?.name ?? "user-subscriptions-drive-pro",
    path: drive_45pro0hXYebJuFpMeta?.path ?? "/user/subscriptions/drive-pro",
    meta: drive_45pro0hXYebJuFpMeta || {},
    alias: drive_45pro0hXYebJuFpMeta?.alias || [],
    redirect: drive_45pro0hXYebJuFpMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/user/subscriptions/drive-pro.vue").then(m => m.default || m)
  },
  {
    name: driveqLLZcUcIb7Meta?.name ?? "user-subscriptions-drive",
    path: driveqLLZcUcIb7Meta?.path ?? "/user/subscriptions/drive",
    meta: driveqLLZcUcIb7Meta || {},
    alias: driveqLLZcUcIb7Meta?.alias || [],
    redirect: driveqLLZcUcIb7Meta?.redirect,
    component: () => import("/builds/leviia/console/pages/user/subscriptions/drive.vue").then(m => m.default || m)
  },
  {
    name: indexR9x1vosQvMMeta?.name ?? "user-subscriptions",
    path: indexR9x1vosQvMMeta?.path ?? "/user/subscriptions",
    meta: indexR9x1vosQvMMeta || {},
    alias: indexR9x1vosQvMMeta?.alias || [],
    redirect: indexR9x1vosQvMMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/user/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: no_45subxk19PBO5crMeta?.name ?? "user-subscriptions-no-sub",
    path: no_45subxk19PBO5crMeta?.path ?? "/user/subscriptions/no-sub",
    meta: no_45subxk19PBO5crMeta || {},
    alias: no_45subxk19PBO5crMeta?.alias || [],
    redirect: no_45subxk19PBO5crMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/user/subscriptions/no-sub.vue").then(m => m.default || m)
  },
  {
    name: object_45storagebTSvacDlVXMeta?.name ?? "user-subscriptions-object-storage",
    path: object_45storagebTSvacDlVXMeta?.path ?? "/user/subscriptions/object-storage",
    meta: object_45storagebTSvacDlVXMeta || {},
    alias: object_45storagebTSvacDlVXMeta?.alias || [],
    redirect: object_45storagebTSvacDlVXMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/user/subscriptions/object-storage.vue").then(m => m.default || m)
  },
  {
    name: partnerNdIRaDpwHWMeta?.name ?? "user-subscriptions-partner",
    path: partnerNdIRaDpwHWMeta?.path ?? "/user/subscriptions/partner",
    meta: partnerNdIRaDpwHWMeta || {},
    alias: partnerNdIRaDpwHWMeta?.alias || [],
    redirect: partnerNdIRaDpwHWMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/user/subscriptions/partner.vue").then(m => m.default || m)
  },
  {
    name: teamXP0SNTzMkqMeta?.name ?? "user-team",
    path: teamXP0SNTzMkqMeta?.path ?? "/user/team",
    meta: teamXP0SNTzMkqMeta || {},
    alias: teamXP0SNTzMkqMeta?.alias || [],
    redirect: teamXP0SNTzMkqMeta?.redirect,
    component: () => import("/builds/leviia/console/pages/user/team.vue").then(m => m.default || m)
  }
]